import React from 'react'
import { Link } from 'gatsby'

import styles from 'components/navigation/navigation.module.scss'

class LevelOneItem extends React.Component {
  render() {
    return (
      <li className={styles.listItem}>
        <Link className={styles.levelOne} to={this.props.to}>{this.props.text}</Link>
      </li>
    )
  }
}

export default LevelOneItem