import React from 'react'

import AnimateHeight from 'react-animate-height'
import LevelTwoItem from 'components/navigation/_level-two-item'

import styles from 'components/navigation/navigation.module.scss'


class NavigationAccordion extends React.Component {

  getHeight() {
    return this.props.isExpanded ? 'auto' : 0
  }

  expandedClass() {
    return this.props.isExpanded ? styles.isExpanded : ''
  }

  clickHandler() {
    this.props.setExpandedIndex(this.props.index)
  }

  render() {

    return (
      <li className={`${styles.listItem} ${this.expandedClass()}`}>
        <div onClick={this.clickHandler.bind(this)} className={styles.levelOne}>{this.props.title}</div>

        <AnimateHeight
          duration={ 500 }
          height={ this.getHeight() }
        >
          <ul className={styles.levelTwoList}>
            {this.props.items.map((item, index) => {
              return <LevelTwoItem to={item.to} text={item.text} key={index} />
            })}
          </ul>
        </AnimateHeight>



      </li>
    )
  }
}

export default NavigationAccordion