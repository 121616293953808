import React from "react"
import NavigationAccordion from "components/navigation/_navigation-accordion"
import LevelOneItem from "components/navigation/_level-one-item"

import styles from "components/navigation/navigation.module.scss"

const ACCORDIONS = [
  {
    title: "About Helios Underwriting",
    items: [
      { to: "/about-helios-underwriting/overview", text: "Overview" },
      {
        to: "/about-helios-underwriting/portfolio-and-capacity",
        text: "Portfolio and capacity",
      },
      {
        to: "/about-helios-underwriting/directors-and-management",
        text: "Directors and management",
      },
      {
        to: "/about-helios-underwriting/corporate-governance",
        text: "Corporate governance",
      },
      { to: "/about-helios-underwriting/advisers", text: "Advisers" },
    ],
  },
  {
    title: "Investors",
    items: [
      { to: "/investors/the-investment-case", text: "The investment case" },
      { to: "/investors/financial-summary", text: "Financial summary" },
      { to: "/investors/announcements", text: "Announcements" },
      { to: "/investors/shareholders", text: "Shareholders" },
      { to: "/investors/reports-and-accounts", text: "Reports and accounts" },
      { to: "/investors/documents-and-papers", text: "Documents and papers" },
      {
        to: "/investors/placing-and-open-offer",
        text: "Placing and Open Offer",
      },
    ],
  },
]

class Navigation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isNavigationVisible: false,
      expandedIndex: this.findExpandedIndex(),
      pathname: null,
    }

    this.toggleNavigationVisibility = this.toggleNavigationVisibility.bind(this)
    this.setExpandedIndex = this.setExpandedIndex.bind(this)
  }

  stripTrailingSlash(string) {
    return string.endsWith("/") ? string.slice(0, -1) : string
  }

  getIsChildSelected(items) {
    for (let item of items) {
      if (
        this.stripTrailingSlash(item.to) ===
        this.stripTrailingSlash(this.props.pathname)
      ) {
        return true
      }
    }
    return false
  }

  findExpandedIndex() {
    const accordionsCount = ACCORDIONS.length
    let accordionIndex = 0
    for (accordionIndex; accordionIndex < accordionsCount; accordionIndex++) {
      if (this.getIsChildSelected(ACCORDIONS[accordionIndex].items)) {
        return accordionIndex
      }
    }
  }

  toggleNavigationVisibility() {
    this.setState(state => ({
      isNavigationVisible: !state.isNavigationVisible,
    }))
  }

  setExpandedIndex(index) {
    const expandedIndex = this.state.expandedIndex === index ? null : index
    this.setState({ expandedIndex: expandedIndex })
  }

  getIsExpanded(index) {
    return this.state.expandedIndex === index
  }

  render() {
    return (
      <nav
        id="js-navigation"
        className={`${
          this.state.isNavigationVisible ? styles.navigationVisible : ""
        } ${styles.navigation}`}
      >
        <div className={styles.header}>
          <div
            className={styles.button}
            onClick={this.toggleNavigationVisibility}
          >
            <div className={styles.icon}></div>
          </div>
        </div>

        <div className={styles.body}>
          <ul className={styles.list}>
            <LevelOneItem to="/" text="Home" />
            {ACCORDIONS.map((accordion, index) => (
              <NavigationAccordion
                key={index}
                title={accordion.title}
                items={accordion.items}
                index={index}
                isExpanded={this.getIsExpanded(index)}
                setExpandedIndex={this.setExpandedIndex}
              />
            ))}
            <LevelOneItem to="/press" text="Press" />
            <LevelOneItem
              to="/sell-your-lloyds-vehicle"
              text="Sell your Lloyd’s vehicle"
            />
            <LevelOneItem to="/contact" text="Contact" />
          </ul>
        </div>
      </nav>
    )
  }
}

export default Navigation
