import React from 'react'
import {Helmet} from 'react-helmet'
import Layout from 'layouts/layout'
import Header from 'components/header'

export default () => (
  <Layout>
    <Helmet>
      <title>
        Legal notices | Helios Underwriting
      </title>
    </Helmet>
    <main>
      <Header layout="standard" />
      <section className="padding-md">
        <h1 className="h1 lg:u-2/3">
          Legal notices
        </h1>
      </section>

      <section className="padding-md lg:u-2/3">
        <p>
          This website is owned and operated by Helios Underwriting Plc ("Helios Underwriting" or "the Company") and provides information on the business activities, product and services of Helios Underwriting. This website is for general information purposes only and is not intended to constitute an offer to solicit business and does not constitute legal or other professional advice.
        </p>
        <p>
          Before using this site, you should read all the legal notices relating to it. This information governs your use of this site and, by using it, you agree to be bound by our terms. If you do not agree please exit this website immediately. The contents of this website, including these terms, are subject to change by us without notice.
        </p>
        <p>
          The communication of information on this website is not intended for distribution to, or use by, any person or entity in any jurisdiction or country where such distribution or use would be contrary to local law or regulation. In particular, the contents and/ or subject matter do not constitute an offer of information, products or services to US persons or in the United States, or in any other jurisdictions where the advertisement, offer or sale of such products and services is restricted or prohibited by law or regulation or where any Helios Underwriting office would be subjected to any registration or licensing requirement. If you use this website from other jurisdictions, you are responsible for compliance with applicable local laws.
        </p>
        <p>
          All references on this website to Helios Underwriting are to Helios Underwriting Plc unless stated to the contrary.
        </p>
        <p>
          The Company does not accept any responsibility for any loss which may arise from reliance on information contained in this site. Permission is given for the downloading and temporary storage of one or more of these pages for the purpose of viewing on a personal computer or monitor. The reproduction (in whole or in part), modification, permanent storage, or retransmission of the contents of this website is prohibited without the prior written consent of Helios Underwriting.
        </p>
      </section>

    </main>
  </Layout>
)