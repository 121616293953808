import React from 'react'
import {Link} from 'gatsby'
import Logo from 'svg/logo'

import SharePriceTicker from 'components/share-price-ticker.js'

export default (props) => (
  <header className={`c-header is-${props.layout}-layout`}>
    <div className="c-header__logo-block">
      <Link to="/">
        <Logo />
      </Link>
    </div>
    <div className="c-header__ticker-block">
      <SharePriceTicker />
    </div>
  </header>
)
