import React from 'react'
import { Link } from 'gatsby'

import styles from 'components/navigation/navigation.module.scss'

class LevelTwoItem extends React.Component {
  render() {
    return (
      <li>
        <Link className={styles.levelTwo} to={this.props.to}>{this.props.text}</Link>
      </li>
    )
  }
}

export default LevelTwoItem