import moment from 'moment'


const DATE_OPTIONS = { day: 'numeric', month: 'long', year: 'numeric' }
const TIME_OPTIONS = { hour: 'numeric', minute: 'numeric' }

const formatMoment = (momentDate, options) => new Intl.DateTimeFormat('en-GB', options).format(momentDate)

export const formatDateTime = (unformatted) => {
  const momentDate = moment(unformatted)
  const dateFormatted = formatMoment(momentDate, DATE_OPTIONS)
  const timeFormatted = formatMoment(momentDate, TIME_OPTIONS)
  return `${dateFormatted} ${timeFormatted}`
}

export const formatDate = (unformatted) => {
  const momentDate = moment(unformatted)
  return formatMoment(momentDate, DATE_OPTIONS)
}

export const formatDecimal = (n) => Number.parseFloat(n).toFixed(2)