import React from 'react'
import { Location } from '@reach/router'
import Navigation from 'components/navigation'
import Footer from 'components/footer'
import {Helmet} from 'react-helmet'

import styles from 'layouts/layout.module.scss'

export default ({ children }) => (
  <div className={styles.container}>
    <Helmet>
      <title>
        Helios Underwriting — Growth and returns from the Lloyd’s market
      </title>
      <meta
        name="description"
        content="Helios Underwriting is acquiring and growing a portfolio made up of the best of Lloyd’s – Limited Liability Vehicles comprising former Lloyd’s Names. This portfolio offers investors income which is uncorrelated to equity market cycles."
      />
    </Helmet>
    <div className={styles.layout}>
      <Location>
        {({ location }) => (
          <Navigation pathname={location.pathname} />
        )}
      </Location>
      {children}
    </div>
    <Footer />
  </div>
)
